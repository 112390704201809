import React, { Component } from 'react';

export default class footer extends Component {
  render() {
    return (
		<div>
			<p style={{margin: '1rem', paddingBottom: '150px'}}> </p> 
		</div>
    )
  }
}
